import gql from 'graphql-tag';


export const validatePhoneNumber = gql`
  query validatePhoneNumber($phoneNumber: String!) {
    invalid: validatePhoneNumber(phoneNumber: $phoneNumber)
  }`;

export const usernameByToken = gql`
  query usernameByToken($meToken: String!) {
    username: usernameByToken(meToken: $meToken)
  }`;

export const FindContadoresRVPerson = gql`
  query FindContadoresRVPerson($criteria:String, $limit:Int, $offset: Int) {
    result: FindContadoresRVPerson(criteria:$criteria, limit:$limit, offset:$offset){
      id
      AditionalInfo
      CompanyName
      Agent
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
      Person {
        id
        DocumentId_Person
        FK_PersonType
        Name_Person
        BirthDate_Person
        Lastname_Person
        PhoneNumber {
          id
          Data_Contact
        }
        Email {
          id
          Data_Contact
        }
        Address {
          id
          Description_Address
        }
      }
    }
  }
`;

export const GetAllContadoresRVBillsAutoByPerson = gql`
  query GetAllContadoresRVBillsAutoByPerson($personId: ID) {
    result: GetAllContadoresRVBillsAutoByPerson(personId:$personId) {
      id
      Mount
      Notes
      IsActive
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
    }
  }`;

export const GetContadoresRVBillBalanceByPersonAndState = gql`
  query GetContadoresRVBillBalanceByPersonAndState($personId: ID, $state: Int) {
    result: GetContadoresRVBillBalanceByPersonAndState(personId:$personId, state:$state)
  }`;

export const GetContadoresRVBillBalanceByCriteriaAndState = gql`
  query GetContadoresRVBillBalanceByCriteriaAndState($criteria: String, $state: Int) {
    result: GetContadoresRVBillBalanceByCriteriaAndState(criteria:$criteria, state:$state)
  }`;

export const GetContadoresRVBillsByPersonAndState = gql`
  query GetContadoresRVBillsByPersonAndState($personId: ID, $limit:Int, $offset: Int, $state: Int) {
    result: GetContadoresRVBillsByPersonAndState(personId:$personId, limit:$limit, offset:$offset, state:$state) {
      id
      Mount
      Balance
      Notes
      Paid
      Created_At
      Updated_At
      Creator {
        id
        Given_Name
      }
      ContadoresRVPerson {
        id
        Creator {
          id
          Given_Name
        }
        Created_At
        Updated_At
        Person {
          id
          DocumentId_Person
          Name_Person
          Lastname_Person
          FK_PersonType
          PhoneNumber {
            id
            Data_Contact
          }
          Email {
            id
            Data_Contact
          }
          Address {
            id
            Description_Address
          }
        }
      }
    }
  }`;

export const GetContadoresRVBillsByCriteriaAndState = gql`
  query GetContadoresRVBillsByCriteriaAndState($criteria: String, $limit:Int, $offset: Int, $state: Int) {
    result: GetContadoresRVBillsByCriteriaAndState(criteria:$criteria, limit:$limit, offset:$offset, state:$state) {
      id
      Mount
      Balance
      Notes
      Paid
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
      ContadoresRVPerson {
        id
        AditionalInfo
        CompanyName
        Agent
        Creator {
          id
          Given_Name
        }
        Created_At
        Updated_At
        Person {
          id
          DocumentId_Person
          Name_Person
          Lastname_Person
          FK_PersonType
          PhoneNumber {
            id
            Data_Contact
          }
          Email {
            id
            Data_Contact
          }
          Address {
            id
            Description_Address
          }
        }
      }
    }
  }`;

export const GetPersonByDocumentId = gql`
  query GetPersonByDocumentId($DocumentId:String) {
    result: GetPersonByDocumentId(DocumentId:$DocumentId) {
      Person {
        id
        Name_Person
        BirthDate_Person
        DocumentId_Person
        FK_PersonType
        Lastname_Person
        PhoneNumber {
          id
          Data_Contact
        }
        Email {
          id
          Data_Contact
        }
        Address {
          id
          Description_Address
        }
      }
      ContadoresRVPerson {
        id
        AditionalInfo
        CompanyName
        Agent
        Creator {
          id
          Given_Name
        }
        Created_At
        Updated_At
        Person {
          id
          DocumentId_Person
          FK_PersonType
          Name_Person
          BirthDate_Person
          Lastname_Person
          Address {
            id
            Description_Address
          }
          PhoneNumber {
            id
            Data_Contact
          }
          Email {
            id
            Data_Contact
          }
        }
      }
    }
  }
`;

export const GetAllContadoresRVPayBillsByBill = gql`
  query GetAllContadoresRVPayBillsByBill($billId: ID) {
    result: GetAllContadoresRVPayBillsByBill(billId:$billId) {
      id
      Mount
      ElectronicBill
      Notes
      Created_At
      Updated_At
      Creator {
        id
        Given_Name
      }
    }
  }`;
