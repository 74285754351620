import gql from 'graphql-tag';

export const userByUID = gql`
  query userByUID($uid: ID) {
    firebaseUser: userByUID(uid: $uid) {
      id
      Email
      UID
      TavuelUser {
        id
        Person {
          id
          Name_Person
          Lastname_Person
          BirthDate_Person
          DocumentId_Person
        }
      }
    }
  }`;

export const firebaseAuth = gql`mutation firebaseAuth($user: GoogleAuthInput) {
  firebaseUser: firebaseAuth(user: $user) {
    id
    UID
    Token
    Email
    Refresh_Token
    Created_At
    Updated_At
  }
}`;

export const updatedUserState = gql`
  mutation updateUserState($user: UserInput) {
    user: updateUserState(user: $user) {
      id
    }
  }`;

export const CreateContadoresRVPerson = gql`
  mutation CreateContadoresRVPerson($data: ContadoresRVPersonInput) {
    result: CreateContadoresRVPerson(data: $data) {
      id
      AditionalInfo
      CompanyName
      Agent
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
      Person {
        id
        DocumentId_Person
        FK_PersonType
        Name_Person
        Lastname_Person
        BirthDate_Person
        FK_PersonType
        Address {
          id
          Description_Address
        }
        PhoneNumber {
          id
          Data_Contact
        }
        Email {
          id
          Data_Contact
        }
      }
    }
  }
`;

export const EditContadoresRVPerson = gql`
  mutation EditContadoresRVPerson($data: ContadoresRVPersonInput) {
    result: EditContadoresRVPerson(data: $data) {
      id
      AditionalInfo
      CompanyName
      Agent
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
      Person {
        id
        DocumentId_Person
        FK_PersonType
        Name_Person
        Lastname_Person
        BirthDate_Person
        FK_PersonType
        Address {
          id
          Description_Address
        }
        PhoneNumber {
          id
          Data_Contact
        }
        Email {
          id
          Data_Contact
        }
      }
    }
  }
`;

export const CreateContadoresRVBillAuto = gql`
  mutation CreateContadoresRVBillAuto($data: ContadoresRVBillAutoInput) {
    result: CreateContadoresRVBillAuto(data: $data) {
      id
      Mount
      Notes
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
      IsActive
    }
  }
`;

export const EditContadoresRVBillAuto = gql`
  mutation EditContadoresRVBillAuto($data: ContadoresRVBillAutoInput) {
    result: EditContadoresRVBillAuto(data: $data) {
      id
      Mount
      Notes
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
      IsActive
    }
  }
`;

export const CreateContadoresRVBill = gql`
  mutation CreateContadoresRVBill($data: ContadoresRVBillInput) {
    result: CreateContadoresRVBill(data: $data) {
      id
      Mount
      Notes
      Paid
      Balance
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
      ContadoresRVPerson {
        id
        AditionalInfo
        CompanyName
        Agent
        Creator {
          id
          Given_Name
        }
        Created_At
        Updated_At
        Person {
          id
          DocumentId_Person
          FK_PersonType
          Name_Person
          Lastname_Person
          PhoneNumber {
            id
            Data_Contact
          }
          Email {
            id
            Data_Contact
          }
          Address {
            id
            Description_Address
          }
        }
      }
    }
  }
`;

export const EditNotesContadoresRVBill = gql`
  mutation EditNotesContadoresRVBill($data: ContadoresRVBillInput) {
    result: EditNotesContadoresRVBill(data: $data) {
      id
      Mount
      Notes
      Paid
      Balance
      Created_At
      Updated_At
      Creator {
        id
        Given_Name
      }
      ContadoresRVPerson {
        id
        AditionalInfo
        CompanyName
        Agent
        Creator {
          id
          Given_Name
        }
        Created_At
        Updated_At
        Person {
          id
          DocumentId_Person
          FK_PersonType
          Name_Person
          Lastname_Person
          PhoneNumber {
            id
            Data_Contact
          }
          Email {
            id
            Data_Contact
          }
          Address {
            id
            Description_Address
          }
        }
      }
    }
  }
`;

export const CreateContadoresRVPayBill = gql`
  mutation CreateContadoresRVPayBill($data: ContadoresRVPayBillInput) {
    result: CreateContadoresRVPayBill(data: $data) {
      id
      Mount
      Notes
      Paid
      Balance
      Creator {
        id
        Given_Name
      }
      Created_At
      Updated_At
      ContadoresRVPerson {
        id
        AditionalInfo
        CompanyName
        Agent
        Creator {
          id
          Given_Name
        }
        Created_At
        Updated_At
        Person {
          id
          DocumentId_Person
          FK_PersonType
          Name_Person
          Lastname_Person
          PhoneNumber {
            id
            Data_Contact
          }
          Email {
            id
            Data_Contact
          }
          Address {
            id
            Description_Address
          }
        }
      }
    }
  }
`;

export const PayAllBillByPerson = gql`
  mutation PayAllBillByPerson($data: PayAllBillByPersonInput) {
    result: PayAllBillByPerson(data: $data)
  }
`;

export const AnuleContadoresRVBill = gql`
  mutation AnuleContadoresRVBill($id: String) {
    result: AnuleContadoresRVBill(id: $id)
  }
`;
