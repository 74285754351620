import { ApiService } from "../services/api.service";
import { Auth } from "@angular/fire/auth";
import { tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { Client } from "../interface/client";
import {
  FindContadoresRVPerson,
  CreateContadoresRVPerson,
  GetPersonByDocumentId,
  EditContadoresRVPerson,
  CreateContadoresRVBillAuto,
  GetAllContadoresRVBillsAutoByPerson,
  EditContadoresRVBillAuto,
  CreateContadoresRVBill,
  EditNotesContadoresRVBill,
  GetContadoresRVBillsByPersonAndState,
  CreateContadoresRVPayBill,
  GetAllContadoresRVPayBillsByBill,
  SetViewMode,
  GetContadoresRVBillsByCriteriaAndState,
  GetContadoresRVBillBalanceByPersonAndState,
  PayAllBillByPerson,
  CleanBills,
  CleanBillsAuto,
  GetContadoresRVBillBalanceByCriteriaAndState,
  AnuleContadoresRVBill,
} from "./actions/client.actions";
import { UserState } from "./user.state";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Injectable, inject } from "@angular/core";

@Injectable()
@State<Client>({
  name: "client",
  defaults: {
    ViewMode: "Clients",
    loadMorePeople: false,
    loadMoreBills: false,
  },
})
export class ClientState {
  @Selector()
  static People({ People }: Client) {
    return People;
  }

  @Selector()
  static PaysBill({ PaysBill }: Client) {
    return PaysBill;
  }

  @Selector()
  static Bills({ Bills }: Client) {
    return Bills;
  }

  @Selector()
  static loadMoreBills({ loadMoreBills }: Client) {
    return loadMoreBills;
  }

  @Selector()
  static loadMorePeople({ loadMorePeople }: Client) {
    return loadMorePeople;
  }

  @Selector()
  static personByDocumentId({ personByDocumentId }: Client) {
    return personByDocumentId;
  }

  @Selector()
  static BillsAuto({ BillsAuto }: Client) {
    return BillsAuto;
  }

  @Selector()
  static BillsByCriteria({ BillsByCriteria }: Client) {
    return BillsByCriteria;
  }

  @Selector()
  static loadMoreBillsByCriteria({ loadMoreBillsByCriteria }: Client) {
    return loadMoreBillsByCriteria;
  }

  @Selector()
  static ViewMode({ ViewMode }: Client) {
    return ViewMode;
  }

  @Selector()
  static BalanceByPersonAndState({ BalanceByPersonAndState }: Client) {
    return BalanceByPersonAndState;
  }

  @Selector()
  static BalanceByCriteriaAndState({ BalanceByCriteriaAndState }: Client) {
    return BalanceByCriteriaAndState;
  }

  private auth: Auth = inject(Auth);

  constructor(
    private api: ApiService,
    private store: Store,
    private snackBar: MatSnackBar
  ) {}

  openSnackBar = (message: string, button: string, duration: number) => {
    this.snackBar.open(message, button, {
      duration,
    });
  };

  @Action(FindContadoresRVPerson)
  findContadoresRVPerson(
    { patchState, getState }: StateContext<Client>,
    { criteria }: FindContadoresRVPerson
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    const { loadMorePeople, People } = getState();
    const limit = 8;
    const offset = !loadMorePeople ? 0 : People.length;
    if (token) {
      return this.api
        .findContadoresRVPerson(token, criteria, limit, offset)
        .pipe(
          tap(({ data: { result } }) => {
            const newPeople =
              !loadMorePeople && offset === 0 ? result : [...People, ...result];
            patchState({
              People: newPeople,
              loadMorePeople:
                !result || result.length < limit || result.length === 0
                  ? false
                  : true,
            });
          }),
          catchError(async (error) => {
            console.log(error);
            this.openSnackBar(
              "Había expirado tu sesión, vuelve a intentarlo",
              "😄",
              3000
            );
            await this.auth.currentUser.getIdToken(true);
            return of(null);
          }),
          catchError((error) => {
            return of(null);
          })
        );
    }
  }

  @Action(GetContadoresRVBillsByPersonAndState)
  getContadoresRVBillsByPersonAndState(
    { patchState, getState }: StateContext<Client>,
    { personId, state, isNew }: GetContadoresRVBillsByPersonAndState
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    const { loadMoreBills, Bills } = getState();
    const limit = 8;
    const offset = !loadMoreBills || isNew ? 0 : Bills.length;
    if (token) {
      return this.api
        .getContadoresRVBillsByPersonAndState(
          token,
          personId,
          limit,
          offset,
          state
        )
        .pipe(
          tap(({ data: { result } }) => {
            const newBills =
              (!loadMoreBills && offset === 0) || isNew
                ? result
                : [...Bills, ...result];
            patchState({
              Bills: newBills,
              loadMoreBills:
                !result || result.length < limit || result.length === 0
                  ? false
                  : true,
            });
          }),
          catchError(async () => {
            this.openSnackBar(
              "Había expirado tu sesión, vuelve a intentarlo",
              "😄",
              3000
            );
            await this.auth.currentUser.getIdToken(true);
            return of(null);
          }),
          catchError((error) => {
            return of(null);
          })
        );
    }
  }

  @Action(GetContadoresRVBillsByCriteriaAndState)
  getContadoresRVBillsByCriteriaAndState(
    { patchState, getState }: StateContext<Client>,
    { criteria, state, isNew }: GetContadoresRVBillsByCriteriaAndState
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    const { loadMoreBillsByCriteria, BillsByCriteria } = getState();
    const limit = 8;
    const offset =
      !loadMoreBillsByCriteria || isNew ? 0 : BillsByCriteria.length;
    if (token) {
      return this.api
        .getContadoresRVBillsByCriteriaAndState(
          token,
          criteria,
          limit,
          offset,
          state
        )
        .pipe(
          tap(({ data: { result } }) => {
            const newBills =
              (!loadMoreBillsByCriteria && offset === 0) || isNew
                ? result
                : [...BillsByCriteria, ...result];
            patchState({
              BillsByCriteria: newBills,
              loadMoreBillsByCriteria:
                !result || result.length < limit || result.length === 0
                  ? false
                  : true,
            });
          }),
          catchError(async () => {
            this.openSnackBar(
              "Había expirado tu sesión, vuelve a intentarlo",
              "😄",
              3000
            );
            await this.auth.currentUser.getIdToken(true);
            return of(null);
          }),
          catchError((error) => {
            return of(null);
          })
        );
    }
  }

  @Action(PayAllBillByPerson)
  payAllBillByPerson(
    { patchState }: StateContext<Client>,
    { data }: PayAllBillByPerson
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.payAllBillByPerson(token, data).pipe(
        tap(({ data: { result } }) => {
          if (result) {
            patchState({
              Bills: [],
              BalanceByPersonAndState: null,
            });
          }
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(AnuleContadoresRVBill)
  anuleContadoresRVBill(
    { patchState, getState }: StateContext<Client>,
    { id }: AnuleContadoresRVBill
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.anuleContadoresRVBill(token, id).pipe(
        tap(({ data: { result } }) => {
          if (result) {
            const { Bills, BalanceByPersonAndState } = getState();
            let newBills = Bills;
            if (Bills) {
              newBills = Bills.filter((b) => b.id !== id);
            }
            patchState({
              BalanceByPersonAndState:
                BalanceByPersonAndState - Bills.find((b) => b.id === id).Mount,
              Bills: Bills.length === 1 ? [] : Bills.filter((b) => b.id !== id),
            });
          }
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(CreateContadoresRVPerson)
  createContadoresRVPerson(
    { patchState, getState }: StateContext<Client>,
    { data }: CreateContadoresRVPerson
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.createContadoresRVPerson(token, data).pipe(
        tap(({ data: { result } }) => {
          let newPeople = [result];
          let found = false;
          const { People } = getState();
          if (People) {
            newPeople = People.map((p) => {
              if (p.id === result.id) {
                found = true;
                return result;
              }
              return p;
            });
            newPeople = !found ? [result, ...newPeople] : newPeople;
          }
          patchState({
            People: newPeople,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(EditContadoresRVPerson)
  editContadoresRVPerson(
    { patchState, getState }: StateContext<Client>,
    { data }: EditContadoresRVPerson
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.editContadoresRVPerson(token, data).pipe(
        tap(({ data: { result } }) => {
          let newPeople = [result];
          let found = false;
          const { People } = getState();
          if (People) {
            newPeople = People.map((p) => {
              if (p.id === result.id) {
                found = true;
                return result;
              }
              return p;
            });
            newPeople = !found ? [result, ...newPeople] : newPeople;
          }
          patchState({
            People: newPeople,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(GetPersonByDocumentId)
  getPersonByDocumentId(
    { patchState }: StateContext<Client>,
    { DocumentId }: GetPersonByDocumentId
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.getPersonByDocumentId(token, DocumentId).pipe(
        tap(({ data: { result } }) => {
          patchState({
            personByDocumentId: result,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(GetAllContadoresRVBillsAutoByPerson)
  getAllContadoresRVBillsAutoByPerson(
    { patchState }: StateContext<Client>,
    { personId }: GetAllContadoresRVBillsAutoByPerson
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.getAllContadoresRVBillsAutoByPerson(token, personId).pipe(
        tap(({ data: { result } }) => {
          patchState({
            BillsAuto: result,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(GetAllContadoresRVPayBillsByBill)
  getAllContadoresRVPayBillsByBill(
    { patchState }: StateContext<Client>,
    { billId }: GetAllContadoresRVPayBillsByBill
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.getAllContadoresRVPayBillsByBill(token, billId).pipe(
        tap(({ data: { result } }) => {
          patchState({
            PaysBill: result,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(CreateContadoresRVBillAuto)
  createContadoresRVBillAuto(
    { patchState, getState }: StateContext<Client>,
    { data }: CreateContadoresRVBillAuto
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.createContadoresRVBillAuto(token, data).pipe(
        tap(({ data: { result } }) => {
          const { BillsAuto } = getState();
          let newBillsAuto = [result];
          let found = false;
          if (BillsAuto) {
            newBillsAuto = BillsAuto.map((p) => {
              if (p.id === result.id) {
                found = true;
                return result;
              }
              return p;
            });
            newBillsAuto = !found ? [result, ...newBillsAuto] : newBillsAuto;
          }
          patchState({
            BillsAuto: newBillsAuto,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(CreateContadoresRVBill)
  createContadoresRVBill(
    { patchState, getState }: StateContext<Client>,
    { data }: CreateContadoresRVBill
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.createContadoresRVBill(token, data).pipe(
        tap(({ data: { result } }) => {
          const { Bills } = getState();
          let newBills = [result];
          let found = false;
          if (Bills) {
            newBills = Bills.map((p) => {
              if (p.id === result.id) {
                found = true;
                return result;
              }
              return p;
            });
            newBills = !found ? [result, ...newBills] : newBills;
          }
          patchState({
            Bills: newBills,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(EditNotesContadoresRVBill)
  editNotesContadoresRVBill(
    { patchState, getState }: StateContext<Client>,
    { data }: EditNotesContadoresRVBill
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.editNotesContadoresRVBill(token, data).pipe(
        tap(({ data: { result } }) => {
          const { Bills } = getState();
          let newBills = [result];
          let found = false;
          if (Bills) {
            newBills = Bills.map((p) => {
              if (p.id === result.id) {
                found = true;
                return result;
              }
              return p;
            });
            newBills = !found ? [result, ...newBills] : newBills;
          }
          patchState({
            Bills: newBills,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(GetContadoresRVBillBalanceByPersonAndState)
  getContadoresRVBillBalanceByPersonAndState(
    { patchState }: StateContext<Client>,
    { personId, state }: GetContadoresRVBillBalanceByPersonAndState
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api
        .getContadoresRVBillBalanceByPersonAndState(token, personId, state)
        .pipe(
          tap(({ data: { result } }) => {
            patchState({
              BalanceByPersonAndState: result ? Number(result) : 0,
            });
          }),
          catchError(async () => {
            this.openSnackBar(
              "Había expirado tu sesión, vuelve a intentarlo",
              "😄",
              3000
            );
            await this.auth.currentUser.getIdToken(true);
            return of(null);
          })
        );
    }
  }

  @Action(GetContadoresRVBillBalanceByCriteriaAndState)
  getContadoresRVBillBalanceByCriteriaAndState(
    { patchState }: StateContext<Client>,
    { criteria, state }: GetContadoresRVBillBalanceByCriteriaAndState
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api
        .getContadoresRVBillBalanceByCriteriaAndState(token, criteria, state)
        .pipe(
          tap(({ data: { result } }) => {
            patchState({
              BalanceByCriteriaAndState: result ? Number(result) : 0,
            });
          }),
          catchError(async () => {
            this.openSnackBar(
              "Había expirado tu sesión, vuelve a intentarlo",
              "😄",
              3000
            );
            await this.auth.currentUser.getIdToken(true);
            return of(null);
          })
        );
    }
  }

  @Action(CleanBillsAuto)
  cleanBillsAuto({ patchState }: StateContext<Client>, {}: CleanBillsAuto) {
    patchState({
      BillsAuto: [],
    });
  }

  @Action(CleanBills)
  cleanBills({ patchState }: StateContext<Client>, {}: CleanBills) {
    patchState({
      Bills: [],
      BalanceByPersonAndState: null,
    });
  }

  @Action(CreateContadoresRVPayBill)
  createContadoresRVPayBill(
    { patchState, getState }: StateContext<Client>,
    { data }: CreateContadoresRVPayBill
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.createContadoresRVPayBill(token, data).pipe(
        tap(({ data: { result } }) => {
          const { Bills, BillsByCriteria, BalanceByPersonAndState } =
            getState();
          let newBills = [];
          let newBillsByCriteria = [];
          let foundBill = false;
          let foundBillByCriteria = false;
          let removeFromBills = false;
          let removeFromBillsByCriteria = false;
          if (Bills && Bills.length > 0) {
            newBills = [result];
            newBills = Bills.map((p) => {
              if (p.id === result.id) {
                foundBill = true;
                if (Number(p.Balance) === Number(data.Mount)) {
                  removeFromBills = true;
                }
                return result;
              }
              return p;
            });
            newBills = !foundBill ? [result, ...newBills] : newBills;
          }
          if (BillsByCriteria && BillsByCriteria.length > 0) {
            newBillsByCriteria = [result];
            newBillsByCriteria = BillsByCriteria.map((p) => {
              if (p.id === result.id) {
                foundBillByCriteria = true;
                if (Number(p.Balance) === Number(data.Mount)) {
                  removeFromBillsByCriteria = true;
                }
                return result;
              }
              return p;
            });
            newBillsByCriteria = !foundBillByCriteria
              ? [result, ...newBillsByCriteria]
              : newBillsByCriteria;
          }
          patchState({
            Bills: removeFromBills
              ? newBills.filter((b) => b.id !== result.id)
              : newBills,
            BillsByCriteria: removeFromBillsByCriteria
              ? newBillsByCriteria.filter((b) => b.id !== result.id)
              : newBillsByCriteria,
            BalanceByPersonAndState: BalanceByPersonAndState - data.Mount,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(EditContadoresRVBillAuto)
  editContadoresRVBillAuto(
    { patchState, getState }: StateContext<Client>,
    { data }: EditContadoresRVBillAuto
  ) {
    const token = this.store.selectSnapshot(UserState.token);
    if (token) {
      return this.api.editContadoresRVBillAuto(token, data).pipe(
        tap(({ data: { result } }) => {
          const { BillsAuto } = getState();
          let newBillsAuto = [result];
          let found = false;
          if (BillsAuto) {
            newBillsAuto = BillsAuto.map((p) => {
              if (p.id === result.id) {
                found = true;
                return result;
              }
              return p;
            });
            newBillsAuto = !found ? [result, ...newBillsAuto] : newBillsAuto;
          }
          patchState({
            BillsAuto: newBillsAuto,
          });
        }),
        catchError(async () => {
          this.openSnackBar(
            "Había expirado tu sesión, vuelve a intentarlo",
            "😄",
            3000
          );
          await this.auth.currentUser.getIdToken(true);
          return of(null);
        })
      );
    }
  }

  @Action(SetViewMode)
  setViewMode({ patchState }: StateContext<Client>, { ViewMode }: SetViewMode) {
    patchState({
      ViewMode,
    });
  }
}
