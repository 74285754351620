import { State, Action, StateContext, Selector, NgxsOnInit } from "@ngxs/store";
import { UI } from "../interface/ui";
import { Platform } from "@angular/cdk/platform";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SwUpdate } from "@angular/service-worker";
import { Title, Meta } from "@angular/platform-browser";
import { Injectable } from "@angular/core";

let win: any;

export class InstallApp {
  static readonly type = "[UI] InstallApp]";
}

export class VerifyInstallPrompt {
  static readonly type = "[UI] VerifyInstallPrompt]";
}
@Injectable()
@State<UI>({
  name: "ui",
  defaults: {},
})
export class UIState implements NgxsOnInit {
  window: any;

  @Selector()
  static device({ device }: UI) {
    return device;
  }

  @Selector()
  static install({ install }: UI) {
    return install;
  }

  @Selector()
  static share({ share }: UI) {
    return share;
  }

  constructor(
    public platform: Platform,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
    private titleService: Title,
    private meta: Meta
  ) {}

  async ngxsOnInit({ dispatch }: StateContext<UI>) {
    dispatch(new VerifyInstallPrompt());
  }

  @Action(VerifyInstallPrompt)
  verifyInstallPrompt({ patchState }: StateContext<UI>) {
    win = window.navigator;
    let share = false;
    if (win.share) {
      share = true;
    }
    window.addEventListener("beforeinstallprompt", (install: any) => {
      install.preventDefault();
      patchState({ install, share });
    });
    const { userAgent, platform } = navigator;
    /* patchState({
      device: { ...this.platform, userAgent, name: platform },
      share,
    });*/
  }

  @Action(InstallApp)
  async installApp({ getState, patchState }: StateContext<UI>) {
    const { install } = getState();
    if (install !== undefined) {
      install.prompt();

      const choiceResult = await install.userChoice;
      console.log(choiceResult.outcome);
      if (choiceResult.outcome === "dismissed") {
        console.log("User cancelled home screen install");
      } else {
        console.log("User added to home screen");
      }
      patchState({ install: null });
    }
  }
}
