import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { HttpClient } from "@angular/common/http";
import {
  validatePhoneNumber,
  usernameByToken,
  FindContadoresRVPerson,
  GetPersonByDocumentId,
  GetAllContadoresRVBillsAutoByPerson,
  GetContadoresRVBillsByPersonAndState,
  GetAllContadoresRVPayBillsByBill,
  GetContadoresRVBillsByCriteriaAndState,
  GetContadoresRVBillBalanceByPersonAndState,
  GetContadoresRVBillBalanceByCriteriaAndState,
} from "./graphql/querys";
import {
  userByUID,
  firebaseAuth,
  updatedUserState,
  CreateContadoresRVPerson,
  EditContadoresRVPerson,
  CreateContadoresRVBillAuto,
  EditContadoresRVBillAuto,
  CreateContadoresRVBill,
  EditNotesContadoresRVBill,
  CreateContadoresRVPayBill,
  PayAllBillByPerson,
  AnuleContadoresRVBill,
} from "./graphql/mutations";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  context = (token) => {
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return {};
  };

  ///////////////////////////////////////
  /////////////QUERIES//////////////////
  //////////////////////////////////////

  validatePhoneNumber = (token, phoneNumber) =>
    this.apollo.query<any>({
      query: validatePhoneNumber,
      variables: { phoneNumber },
      context: this.context(token),
    });

  usernameByToken = (token, meToken) =>
    this.apollo.query<any>({
      query: usernameByToken,
      variables: { meToken },
      context: this.context(token),
    });

  findContadoresRVPerson = (token, criteria, limit, offset) =>
    this.apollo.use("Contadores").query<any>({
      query: FindContadoresRVPerson,
      context: this.context(token),
      variables: {
        criteria,
        limit,
        offset,
      },
    });

  getAllContadoresRVBillsAutoByPerson = (token, personId) =>
    this.apollo.use("Contadores").query<any>({
      query: GetAllContadoresRVBillsAutoByPerson,
      context: this.context(token),
      variables: {
        personId,
      },
    });

  getContadoresRVBillBalanceByPersonAndState = (token, personId, state) =>
    this.apollo.use("Contadores").query<any>({
      query: GetContadoresRVBillBalanceByPersonAndState,
      context: this.context(token),
      variables: {
        personId,
        state,
      },
    });

  getContadoresRVBillBalanceByCriteriaAndState = (token, criteria, state) =>
    this.apollo.use("Contadores").query<any>({
      query: GetContadoresRVBillBalanceByCriteriaAndState,
      context: this.context(token),
      variables: {
        criteria,
        state,
      },
    });

  getPersonByDocumentId = (token, DocumentId) =>
    this.apollo.use("Contadores").query<any>({
      query: GetPersonByDocumentId,
      context: this.context(token),
      variables: {
        DocumentId,
      },
    });

  getContadoresRVBillsByPersonAndState = (
    token,
    personId,
    limit,
    offset,
    state
  ) =>
    this.apollo.use("Contadores").query<any>({
      query: GetContadoresRVBillsByPersonAndState,
      context: this.context(token),
      variables: {
        personId,
        limit,
        offset,
        state,
      },
    });

  getContadoresRVBillsByCriteriaAndState = (
    token,
    criteria,
    limit,
    offset,
    state
  ) =>
    this.apollo.use("Contadores").query<any>({
      query: GetContadoresRVBillsByCriteriaAndState,
      context: this.context(token),
      variables: {
        criteria,
        limit,
        offset,
        state,
      },
    });

  getAllContadoresRVPayBillsByBill = (token, billId) =>
    this.apollo.use("Contadores").query<any>({
      query: GetAllContadoresRVPayBillsByBill,
      context: this.context(token),
      variables: {
        billId,
      },
    });

  ///////////////////////////////////////
  /////////////MUTATION//////////////////
  //////////////////////////////////////

  createContadoresRVPerson = (token, data) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: CreateContadoresRVPerson,
      context: this.context(token),
      variables: {
        data,
      },
    });

  editContadoresRVPerson = (token, data) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: EditContadoresRVPerson,
      context: this.context(token),
      variables: {
        data,
      },
    });

  payAllBillByPerson = (token, data) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: PayAllBillByPerson,
      context: this.context(token),
      variables: {
        data,
      },
    });

  createContadoresRVBillAuto = (token, data) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: CreateContadoresRVBillAuto,
      context: this.context(token),
      variables: {
        data,
      },
    });

  editContadoresRVBillAuto = (token, data) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: EditContadoresRVBillAuto,
      context: this.context(token),
      variables: {
        data,
      },
    });

  anuleContadoresRVBill = (token, id) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: AnuleContadoresRVBill,
      context: this.context(token),
      variables: {
        id,
      },
    });

  createContadoresRVBill = (token, data) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: CreateContadoresRVBill,
      context: this.context(token),
      variables: {
        data,
      },
    });

  editNotesContadoresRVBill = (token, data) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: EditNotesContadoresRVBill,
      context: this.context(token),
      variables: {
        data,
      },
    });

  createContadoresRVPayBill = (token, data) =>
    this.apollo.use("Contadores").mutate<any>({
      mutation: CreateContadoresRVPayBill,
      context: this.context(token),
      variables: {
        data,
      },
    });

  userByUID = (token, uid) =>
    this.apollo.mutate<any>({
      mutation: userByUID,
      context: this.context(token),
      variables: { uid },
    });

  firebaseAuth = (user) =>
    this.apollo.mutate<any>({
      mutation: firebaseAuth,
      variables: { user },
    });

  updatedUserState = (token, user) =>
    this.apollo.mutate<any>({
      mutation: updatedUserState,
      context: this.context(token),
      variables: { user },
    });
}
