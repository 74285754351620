<!--The content below is only a placeholder and can be replaced.-->

<div class="app" *ngIf="!loading">
  <ng-container *ngIf="isGhost | async as isGhost">
    <mat-toolbar class="nav-bar container" *ngIf="isGhost === '0'">
      <div class="w-20">
        <img width="48" height="48" loading="lazy" class="image-contadores" alt="Contadores RV"
          src="assets/icons/icon-48x48.webp" />
          <button mat-button class="install-button" (click)="installApp()" *ngIf="install | async"><mat-icon>arrow_downward</mat-icon> Instalar</button>
      </div>
      <div class="w-80 container grid content-right">
        <ng-container *ngIf="ViewMode | async as ViewMode">
          <ng-container *ngIf="!mobile">
            <button mat-button
              [ngClass]="{'green': ViewMode === 'Clients' }" (click)="setViewMode('Clients')"><mat-icon>people</mat-icon> Clientes</button>
            <button mat-button [ngClass]="{'green': ViewMode === 'PendingBills' }" (click)="setViewMode('PendingBills')"> <mat-icon>thumbs_up_down</mat-icon> Cuentas por cobrar</button>
            <button mat-button color="warm" (click)="logout()"><mat-icon>exit_to_app</mat-icon> Cerrar Sesión</button>
          </ng-container>
          <ng-container *ngIf="mobile">
            <button mat-icon-button [matMenuTriggerFor]="loggedUser" aria-label="View account options">
              <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #loggedUser="matMenu">
              <button mat-menu-item [ngClass]="{'green': ViewMode === 'Clients' }" (click)="setViewMode('Clients')">
                <mat-icon>people</mat-icon>Clientes
              </button>
              <button mat-menu-item [ngClass]="{'green': ViewMode === 'PendingBills' }" (click)="setViewMode('PendingBills')">
                <mat-icon>thumbs_up_down</mat-icon> Cuentas por cobrar
              </button>
              <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon> Cerrar sesión
              </button>
            </mat-menu>
          </ng-container>
        </ng-container>
  
  
      </div>
    </mat-toolbar>
  </ng-container>
  <main>
    <router-outlet> </router-outlet>
  </main>
</div>

<div class="page content-center vertical-center" *ngIf="loading">
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
