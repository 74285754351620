import { Component, OnInit } from '@angular/core';
import { UserState } from './states/user.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SignOut } from './states/actions/user.actions';
import { SetViewMode } from './states/actions/client.actions';
import { ClientState } from './states/client.state';
import { InstallApp, UIState } from './states/ui.state';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent implements OnInit {
  @Select(UserState.isGhost) isGhost: Observable<string>;
  @Select(ClientState.ViewMode) ViewMode: Observable<string>;
  @Select(UIState.install) install: Observable<string>;

  loading = false;
  mobile = false;
  constructor(
    private store: Store,
    private deviceService: DeviceDetectorService,
  ) { }

  ngOnInit() {
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.mobile = true;
    }
  }

  installApp = () => {
    this.store.dispatch(new InstallApp());
  }

  logout = () => {
    this.store.dispatch(new SignOut());
  }

  setViewMode = mode => {
    this.store.dispatch(new SetViewMode(mode));
  }
}
