import {
  APOLLO_NAMED_OPTIONS,
  APOLLO_OPTIONS,
  ApolloModule,
  NamedOptions,
} from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { NgModule } from "@angular/core";
import { InMemoryCache } from "@apollo/client/core";
import { environment } from "environments/environment";

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          default: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: environment.api,
            }),
          },

          Contadores: {
            cache: new InMemoryCache(),
            link: httpLink.create({ uri: environment.apiContadores }),
          },
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class ApolloClientsModule {}
