export class Login {
  static readonly type = '[User] Login]';
  constructor(public readonly credentials: { email: any; password: any }) { }
}

export class SignOut {
  static readonly type = '[User] SignOut]';
  constructor() { }
}

export class UpdateUserSession {
  static readonly type = '[User] UpdateUserSession]';
  constructor(public readonly info: {
    firebaseUser: any,
    token: string,
    isGhost: string,
  }) { }
}

export class WatchIdToken {
  static readonly type = '[User] WatchIdToken]';
}

export class FirebaseAuth {
  static readonly type = '[User] FirebaseAuth]';
  constructor(public readonly user: any, public readonly ghostUID?: any) { }
}

export class UserByUID {
  static readonly type = '[User] UserByUID]';
  constructor(public readonly token: string, public readonly uid: string) { }
}

export class ResetPasswordEmail {
  static readonly type = '[User] ResetPasswordEmail]';
  constructor(public readonly email: string) { }
}


