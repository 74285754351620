import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { ReactiveFormsModule } from "@angular/forms";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { UserState } from "./states/user.state";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { ApolloClientsModule } from "./apollo-clients-module";
import { ApiService } from "./services/api.service";
import { UIState } from "./states/ui.state";
import { ClientState } from "./states/client.state";
import { DeviceDetectorService } from "ngx-device-detector";
import { MatMenuModule } from "@angular/material/menu";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { PerformanceModule } from "@angular/fire/performance";

@NgModule({
  declarations: [AppComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    BrowserModule,
    HttpClientModule,
    ApolloClientsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsModule.forRoot([UserState, ClientState, UIState]),
    MatButtonModule,
    MatMenuModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    PerformanceModule,
  ],
  exports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatMenuModule,
  ],
  providers: [ApiService, DeviceDetectorService],
  bootstrap: [AppComponent],
})
export class AppModule {}
