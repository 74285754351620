import { Navigate } from "@ngxs/router-plugin";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "../services/api.service";
import {
  Auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "@angular/fire/auth";
import { tap, retryWhen } from "rxjs/operators";
import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { User } from "../interface/user";
import { unauthorizedRetryStrategy } from "../pipes/unauthorized/unauthorized.pipe";
import {
  SignOut,
  FirebaseAuth,
  UpdateUserSession,
  UserByUID,
  WatchIdToken,
  Login,
  ResetPasswordEmail,
} from "./actions/user.actions";
import { Injectable, inject } from "@angular/core";
import { Performance } from "@angular/fire/performance";

@Injectable()
@State<User>({
  name: "user",
  defaults: {
    isGhost: "1",
  },
})
export class UserState {
  installPrompt: any;

  @Selector()
  static me({ firebaseUser: { TavuelUser } }: User) {
    return TavuelUser;
  }

  @Selector()
  static token({ token }: User) {
    return token;
  }

  @Selector()
  static isGhost(state: User) {
    return state.isGhost;
  }

  @Selector()
  static firebaseUser({ firebaseUser }: User) {
    return firebaseUser;
  }

  @Selector()
  static UID({ firebaseUser: { UID } }: User) {
    return UID;
  }

  private auth: Auth = inject(Auth);

  constructor(
    private api: ApiService,
    private store: Store,
    private snackBar: MatSnackBar
  ) {}

  ngxsOnInit({ dispatch }: StateContext<User>) {
    this.auth.useDeviceLanguage();
    dispatch(new WatchIdToken());
  }

  openSnackBar = (
    message: string,
    button: string,
    selectedClass: string,
    duration: number
  ) => {
    this.snackBar.open(message, button, {
      duration,
      panelClass: [selectedClass],
    });
  };

  @Action(UpdateUserSession)
  UpdateUserSession(
    { patchState }: StateContext<User>,
    { info }: UpdateUserSession
  ) {
    const { firebaseUser, token, isGhost } = info;
    patchState({ firebaseUser, token, isGhost });
  }

  @Action(WatchIdToken)
  watchIdToken({ dispatch }: StateContext<User>) {
    this.auth.useDeviceLanguage();
    return this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        if (token) {
          const info = {
            token,
            firebaseUser: { UID: user.uid },
            isGhost: user.isAnonymous ? "1" : "0",
          };
          dispatch(new UserByUID(token, user.uid));
          dispatch(new Navigate([user.isAnonymous ? "/home" : "/cms"]));
        }
      }
    });
  }

  @Action(UserByUID)
  userByUID({ dispatch }: StateContext<User>, { token, uid }: UserByUID) {
    return this.api.userByUID(token, uid).pipe(
      tap(({ data: { firebaseUser } }) => {
        if (firebaseUser) {
          if (firebaseUser.TavuelUser) {
            const info = {
              firebaseUser,
              me: firebaseUser.TavuelUser,
              token,
              isGhost: "0",
            };
            dispatch(new UpdateUserSession(info));
          } else {
            const info = { firebaseUser, me: null, token, isGhost: "0" };
            dispatch(new UpdateUserSession(info));
            dispatch(new Navigate(["/profile"]));
          }
        } else {
          const info = { firebaseUser: { UID: uid }, token, isGhost: "1" };
          dispatch(new UpdateUserSession(info));
        }
      }),
      retryWhen(
        unauthorizedRetryStrategy({ afAuth: this.auth, maxRetryAttempts: 3 })
      )
    );
  }

  @Action(SignOut)
  signOut({ patchState, dispatch }: StateContext<User>) {
    return this.auth.signOut().then(() => {
      patchState({
        firebaseUser: null,
        isGhost: "1",
        token: null,
      });
      dispatch(new Navigate(["home"]));
    });
  }

  @Action(FirebaseAuth)
  firebaseAuth(
    { getState, dispatch }: StateContext<User>,
    { user, ghostUID }: FirebaseAuth
  ) {
    const {
      user: { uid, email, emailVerified, phoneNumber, displayName, providerId },
    } = user;

    let signInUser: any = {
      Email: email,
      Name: displayName,
      Verified_Email: emailVerified,
      Phone_Number: phoneNumber,
      UID: uid,
      Provider_Id: providerId,
      GhostUID: ghostUID,
    };
    if (signInUser) {
      signInUser = {
        ...signInUser,
        Locale: navigator.language,
      };
    }
    const { token, isGhost } = getState();
    return this.api.firebaseAuth(signInUser).pipe(
      tap(() => {
        if (isGhost === "0") {
          dispatch(new UserByUID(token, uid));
        } else {
          dispatch(new Navigate(["/cms"]));
        }
      }),
      retryWhen(
        unauthorizedRetryStrategy({ afAuth: this.auth, maxRetryAttempts: 3 })
      )
    );
  }

  @Action(Login)
  login({ dispatch }: StateContext<User>, { credentials }: Login) {
    const ghostUID = this.store.selectSnapshot(UserState.UID);
    return signInWithEmailAndPassword(
      this.auth,
      credentials.email,
      credentials.password
    )
      .then(async (userCredentials) => {
        await dispatch(new FirebaseAuth(userCredentials, ghostUID));
      })
      .catch(({ message }) => {
        this.snackBar.open(message, "Ok", { duration: 3000 });
      });
  }

  @Action(ResetPasswordEmail)
  ResetPasswordEmail({}: StateContext<User>, { email }: ResetPasswordEmail) {
    sendPasswordResetEmail(this.auth, email)
      .then((res) => {
        this.openSnackBar(
          "Email enviado con exito",
          "OK",
          "custom-snackbar",
          3000
        );
      })
      .catch(() => {
        this.openSnackBar(
          "Error enviar el email",
          "OK",
          "custom-snackbar-error",
          3000
        );
      });
  }
}
