import {
  ContadoresRVPersonInput,
  ContadoresRVBillAutoInput,
  ContadoresRVBillInput,
  ContadoresRVPayBillInput,
} from "../../interface/client";

export class FindContadoresRVPerson {
  static readonly type = "[Client] Query FindContadoresRVPerson";
  constructor(public readonly criteria: string) {}
}

export class CreateContadoresRVPerson {
  static readonly type = "[Client] Mutation CreateContadoresRVPerson";
  constructor(public readonly data: ContadoresRVPersonInput) {}
}

export class EditContadoresRVPerson {
  static readonly type = "[Client] Mutation  EditContadoresRVPerson";
  constructor(public readonly data: ContadoresRVPersonInput) {}
}

export class CleanBillsAuto {
  static readonly type = "[Client] Action  CleanBillsAuto";
  constructor() {}
}

export class CleanBills {
  static readonly type = "[Client] Action CleanBills";
  constructor() {}
}

export class CreateContadoresRVBillAuto {
  static readonly type = "[Client] Mutation CreateContadoresRVBillAuto";
  constructor(public readonly data: ContadoresRVBillAutoInput) {}
}

export class EditContadoresRVBillAuto {
  static readonly type = "[Client] Mutation EditContadoresRVBillAuto";
  constructor(public readonly data: ContadoresRVBillAutoInput) {}
}

export class CreateContadoresRVBill {
  static readonly type = "[Client] Mutation CreateContadoresRVBill";
  constructor(public readonly data: ContadoresRVBillInput) {}
}

export class EditNotesContadoresRVBill {
  static readonly type = "[Client] Mutation EditNotesContadoresRVBill";
  constructor(public readonly data: ContadoresRVBillInput) {}
}

export class CreateContadoresRVPayBill {
  static readonly type = "[Client] Mutation CreateContadoresRVPayBill";
  constructor(public readonly data: ContadoresRVPayBillInput) {}
}

export class GetAllContadoresRVBillsAutoByPerson {
  static readonly type = "[Client] Query GetAllContadoresRVBillsAutoByPerson";
  constructor(public readonly personId: string) {}
}

export class GetContadoresRVBillBalanceByPersonAndState {
  static readonly type =
    "[Client] Query GetContadoresRVBillBalanceByPersonAndState";
  constructor(
    public readonly personId: string,
    public readonly state: number
  ) {}
}

export class GetContadoresRVBillBalanceByCriteriaAndState {
  static readonly type =
    "[Client] Query GetContadoresRVBillBalanceByCriteriaAndState";
  constructor(
    public readonly criteria: string,
    public readonly state: number
  ) {}
}

export class PayAllBillByPerson {
  static readonly type = "[Client] Mutation PayAllBillByPerson";
  constructor(public readonly data: ContadoresRVPayBillInput) {}
}

export class GetContadoresRVBillsByPersonAndState {
  static readonly type = "[Client] Query GetContadoresRVBillsByPersonAndState";
  constructor(
    public readonly personId: string,
    public readonly state: number,
    public readonly isNew: boolean
  ) {}
}

export class GetContadoresRVBillsByCriteriaAndState {
  static readonly type =
    "[Client] Query GetContadoresRVBillsByCriteriaAndState";
  constructor(
    public readonly criteria: string,
    public readonly state: number,
    public readonly isNew: boolean
  ) {}
}

export class GetAllContadoresRVPayBillsByBill {
  static readonly type = "[Client] Query GetAllContadoresRVPayBillsByBill";
  constructor(public readonly billId: string) {}
}

export class GetPersonByDocumentId {
  static readonly type = "[Client] Query GetPersonByDocumentId";
  constructor(public readonly DocumentId: string) {}
}

export class SetViewMode {
  static readonly type = "[Client] SetViewMode";
  constructor(public readonly ViewMode: string) {}
}

export class AnuleContadoresRVBill {
  static readonly type = "[Client] Mutation AnuleContadoresRVBill";
  constructor(public readonly id: string) {}
}
