import { Observable, throwError, timer, of } from "rxjs";
import { mergeMap, finalize } from "rxjs/operators";
import { Auth } from "@angular/fire/auth";

export const unauthorizedRetryStrategy =
  ({
    maxRetryAttempts = 1,
    scalingDuration = 1000,
    excludedStatusCodes = [],
    afAuth,
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
    afAuth?: Auth;
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap(async (error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt < maxRetryAttempts && afAuth.currentUser) {
          await afAuth.currentUser.getIdToken(true);
          return timer(retryAttempt * scalingDuration);
        } else {
          return throwError(error);
        }
      }),
      finalize(() => console.log("We are done!"))
    );
  };
